import { Paper } from "@mui/material";
import React from "react";

export default function ThankYou() {
    return (
        <div
            style={{
                width: "100vw",
                height: "100vh",
            }}
        >
            <Paper
                elevation={3}
                sx={{
                    position: "absolute",
                    top: "50vh",
                    left: "49vw",
                    transform: "translate(-50%, -50%)",
                    borderRadius: "20px",
                }}
                className="thank-you-container"
            >
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                        margin: "20px",
                    }}
                >
                    <div>
                        <div className="mb-4 text-center">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="75"
                                height="75"
                                fill="currentColor"
                                className="text-success bi bi-check-circle-fill"
                                viewBox="0 0 16 16"
                            >
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                            </svg>
                        </div>
                        <div className="text-center">
                            <h1>Thank You !</h1>
                            <button
                                className="btn btn-primary"
                                onClick={() => (window.location.href = "/")}
                                style={{
                                    marginTop: 10,
                                }}
                            >
                                Back Home
                            </button>
                        </div>
                    </div>
                </div>
            </Paper>
        </div>
    );
}
