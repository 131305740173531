import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
  } from "@mui/material";
  
  export default function PopUp({ open, data }) {
    return (
      <Dialog open={open} onClose={data?.onClose}>
        <DialogTitle>{data?.title}</DialogTitle>
        <DialogContent
          sx={{
            whiteSpace: "pre-line",
          }}
        >
          <p>{data?.content}</p>
        </DialogContent>
        <DialogActions>
          {data?.isConfirm && (
            <Button onClick={data?.onConfirm} color={data?.color} variant="contained">
              {data?.confirmText}
            </Button>
          )}
          <Button onClick={data?.onClose} variant="contained" color={ data?.isConfirm ?  "error" : "success"}>{data.closeText}</Button>
        </DialogActions>
      </Dialog>
    );
  }
  